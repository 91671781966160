<template>
    <div>
        <div class="col-md-12">
            <table width="100%" border="1">
                <tr>
                    <td style="font-size:13px; font-weight:700;">CODIGO: </td>
                    <td style="font-size:13px; font-weight:700; color:black;">{{ producto.codigo }}</td>
                </tr>
                <tr>
                    <td style="font-size:13px; font-weight:700;">NOMBRE: </td>
                    <td colspan="3" style="font-size:13px; font-weight:700; color:black;">{{ producto.nombre }}</td>
                </tr>
                <tr>
                    <td style="font-size:13px; font-weight:700;">CATEGORIA: </td>
                    <td colspan="3" style="font-size:13px; font-weight:700; color:black;">{{ producto.categoria.nombre }}</td>
                </tr>
            </table>
            <br>
            
            <a-col :span="20">
                <label class="control-label">Categoria: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                        
                <a-col :span="24">
                    <span v-if="form.subcategoria === false" class="badge badge-warning" style="font-size:8px;">Categorias:</span>
                    <span v-if="form.subcategoria === true" class="badge badge-warning" style="font-size:8px;">SubCategorias:</span>
                    <span v-if="form.subcategoria === false" style="font-size:10px;"> ¿ Utilizar SubCategorias ?  <a-switch :checked="form.subcategoria" @change="onSwitchSubCategoria" /></span>
                    <span v-if="form.subcategoria === true" style="font-size:10px;"> Habilitado  <a-switch :checked="form.subcategoria" @change="onSwitchSubCategoria" /></span>
                </a-col>
                        
                <a-select 
                    label-in-value 
                    :value="{ key: data.categoriaID }" 
                    @change="onSelectCategoria" 
                    show-search
                    option-filter-prop="children" 
                    :filter-option="filterOptionCategoria" 
                    style="width:100%"
                    size="large"
                    >
                    <a-select-option v-for="(value, index) in categorias" :key="index" :value="value.categoria_id"> {{ value.nombre }}</a-select-option>
                </a-select>
                <br>
                <a-alert v-if="data.nombreSubCategoria" :message="'SubCategoria : ' + data.nombreSubCategoria" type="success" show-icon />
            </a-col>

            <a-col :span="3">
                <a-button 
                    size="large" 
                    type="dashed"
                    style="top: 48px;"
                    @click="abriModalSecundary('categoria')"
                    >
                    <a-icon type="plus" :style="{ fontSize: '18px', color: '#08c' }" />
                </a-button>
                <br><br><br><br>
            </a-col>

            <a-button 
                type="primary"
                size="large"
                block 
                @click.once="editarProductoCategoria()" 
                :key="buttonKeyProductoProveedor">
                <a-icon type="form" :style="{ fontSize: '22px', color: '#FFFFFF', fontWeight: '700' }"/> ACTUALIZAR CATEGORIA
            </a-button>
        </div>
    
        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1055;">
            <div style="margin-top: 250px;">
                <vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
                <br><br>
                <center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
            </div>
        </div>

        <!--CATEGORIAS-->
        <a-modal
            v-model="modalCategoria"
            title="GESTION DE CATEGORIA"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :width="420"
            :zIndex="1050"
            >
    
            <div class="row mb-12">
                <div class="form-group">
                    <label>Nombre: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                    <a-input size="large" v-model="data.nombreCategoria" placeholder="Nombre de la Categoria" onfocus="myFunction(this)"/>
                </div>
            </div>
    
            <template slot="footer">
                <a-button key="back" @click="modalCategoria = false">
                    CANCELAR
                </a-button>
                <a-button @click.once="guardarCategoria()" :key="buttonKey" type="primary" :loading="loading">GUARDAR CATEGORIA</a-button>
            </template>
        </a-modal>

         <!--SUB CATEGORIAS-->
         <a-modal
            v-model="modalVisualSubCategoria"
            title="GESTION DE SUBCATEGORIA"
            :footer="false"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :width="420"
            :zIndex="1050"
            >
    
            <div class="row mb-12">
                <span>Categoria :</span>
                <h1 style="margin-top: 2px;"> {{ data.nombreCategoria }}</h1>
                <a-col :span="20">
                    <label for="almacen" class="control-label">SubCategoria: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                    <a-select 
                        label-in-value 
                        :value="{ key: data.subcategoriaID }" 
                        @change="onSelectSubCategoria" 
                        show-search
                        option-filter-prop="children" 
                        :filter-option="filterOptionSubCategoria" 
                        style="width:100%"
                        size="large"
                        >
                        <a-select-option v-for="(value, index) in subcategorias" :key="index" :value="value.subcategoria_id"> {{ value.nombre }}</a-select-option>
                    </a-select>
                </a-col>

                <a-col :span="3">
                    <a-button 
                        size="large" 
                        type="dashed"
                        style="top: 26px;"
                        @click="abriModalSecundary('subcategoria')"
                        >
                        <a-icon type="plus" :style="{ fontSize: '18px', color: '#08c' }" />
                    </a-button>
                </a-col>
            </div>
        </a-modal>

        <!--MODAL CREAR SUB CATEGORIAS-->
        <a-modal
            v-model="modalSubCategoria"
            title="CREAR SUBCATEGORIAS"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :width="420"
            :zIndex="1055"
            >
    
            <div class="row mb-12">
                <div class="form-group">
                    <label>Nombre: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                    <a-input size="large" v-model="data.nombreSubCategoria" placeholder="Nombre de la SubCategoria" onfocus="myFunction(this)"/>
                </div>
            </div>
    
            <template slot="footer">
                <a-button key="back" @click="modalSubCategoria = false">
                    CANCELAR
                </a-button>
                <a-button @click.once="guardarSubCategoria()" :key="buttonKey" type="primary" :loading="loading">GUARDAR SUBCATEGORIA</a-button>
            </template>
        </a-modal>
    </div>
</template>
    
<script>
    import axios from '@/config/axios/axios'
    import * as utils from '@/utils/utils'
    import Swal from 'sweetalert2'
    import { mapGetters, mapActions } from 'vuex'
    import { EuropioCode } from '@/utils/europiocode'

    import { 
		URI_CATEGORIA_GUARDAR
	} from '@/utils/constants'

    export default {
    
        props: {
            producto: { required: true }
        },
    
        data () {
            return {
                lista_proveedores: [],
                spinnerloading: false,
                loading: false,
                data: {
                    productoID: '',
                    categoriaID: '',
                    nombre: '',
                    nombreCategoria: '',
                    nombreSubCategoria: ''
                },
                buttonKeyProductoProveedor: false,
                modalCategoria: false,
                modalSubCategoria: false,
                modalVisualSubCategoria: false,
                buttonKey: 10,
                form: {
                    subcategoria: false
                }
            }
        },
    
        created () {
            this.initData(this.producto)
            this.getCategoria()
        },

        computed: {
            ...mapGetters('categoria', ['categorias']),
            ...mapGetters('subcategoria', ['subcategorias'])
        },
    
        methods: {
            ...mapActions('categoria', ['getCategoria']),
            ...mapActions('subcategoria', ['create', 'getSubCategoria']),
            ...mapActions('producto', ['updateCategoria']),

            initData (item) {
                this.data.productoID = ''
                this.data.categoriaID = ''
                this.data.nombre = ''
                this.data.nombreCategoria = ''
                
                setTimeout(function () { 
                    this.data.productoID = item.producto_id.toString()
                    this.data.categoriaID = item.categoriaID.toString()
                    this.data.subcategoriaID = item.subcategoriaID.toString()
                    this.data.nombre = item.nombre
                    this.data.nombreCategoria = item.categoria.nombre
                    this.data.nombreSubCategoria = item.subcategoria.nombre

                    const valorSubCategoria = localStorage.getItem('switch-subcategoria')
                    if (valorSubCategoria) {
                        if (valorSubCategoria === 'HABILITADO') {
                            this.form.subcategoria = true
                        } else {
                            this.form.subcategoria = false
                        }
                    } else {
                        this.form.subcategoria = false
                    }
                }.bind(this), 300)
            },

            onSwitchSubCategoria (value) {
                const valor = (value === true) ? 'HABILITADO' : 'DESHABILITADO'
                const nombre = 'switch-subcategoria'
                localStorage.setItem(nombre, valor)
                this.form.subcategoria = value
            },

            filterOptionCategoria (input, option) {
                return (
                    option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
                )
            },

            filterOptionSubCategoria (input, option) {
                return (
                    option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
                )
            },

            listaSubCategoria () {
                this.spinnerloading = true
                this.getSubCategoria(this.data.categoriaID) 
                .then(response => {
                    if (response.status === 200) {
                        this.spinnerloading = false
                    }
                })
                .catch(error => {
                    this.loading = false
                    utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
                })
            },

            onSelectCategoria (value) {
                this.data.categoriaID = value.key
                this.data.nombreCategoria = value.label
                if (this.form.subcategoria === true) {
                    this.data.subcategoriaID = 'Seleccionar'
                    this.data.nombreSubCategoria = ''
                    this.listaSubCategoria()
                    this.modalVisualSubCategoria = true
                }
            },

            onSelectSubCategoria (value) {
                this.data.subcategoriaID = value.key
                this.data.nombreSubCategoria = value.label
                this.modalVisualSubCategoria = false
            },

            abriModalSecundary (tipo) {
                switch (tipo) {
                    case 'categoria':
                        this.modalCategoria = true
                        break
                    case 'subcategoria':
                        this.modalSubCategoria = true
                        break
                    default:
                }
            },

            guardarCategoria () {
                Swal.fire({
                        title: 'Desea Agregar y Guardar?',
                        text: 'Tome en cuenta que debera registrar con cuidado los campos ingresados y no debe contener datos duplicados en el sistema.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, Proceder Ahora',
                        cancelButtonClass: 'btn btn-danger',
                        confirmButtonClass: 'btn btn-success',
                        cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
                        this.spinnerloading = true
                        const ecode = new EuropioCode()
                        const formData = new FormData()
                        formData.append('nombre', ecode.encode(this.data.nombreCategoria))
                        axios.post(URI_CATEGORIA_GUARDAR, formData)
                        .then(response => {
                            if (response.status === 201) {
                                this.getCategoria()
                                this.spinnerloading = false
                                this.modalCategoria = false
                                utils.openNotificationWithIcon('success', 'Mensaje : ', 'Haz realizado con éxito el proceso de la información', 'topRight')
                            }
                        })
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                    }
                })
                this.buttonKey++
            },

            guardarSubCategoria () {
                this.spinnerloading = true
				this.create(this.data)
                .then(response => {
                    if (response.status === 201) {
                        this.listaSubCategoria()
					    utils.openNotificationWithIcon('success', 'Exito', response.data, 'topRight')
                        this.modalSubCategoria = false
                        this.spinnerloading = false
                    }
				})
                .catch(error => {
					this.loading = false
					utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
				})
                this.buttonKey++
            },

            editarProductoCategoria () {
                this.spinnerloading = true
				this.updateCategoria(this.data)
                .then(response => {
                    if (response.status === 201) {
                        this.$emit('successActualizarCategoria', response.data.message)
					    utils.openNotificationWithIcon('success', 'Exito', response.data, 'topRight')
                        this.modalCategoria = false
                        this.spinnerloading = false
                    }
				})
                .catch(error => {
					this.loading = false
					utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
				})
                this.buttonKeyProductoProveedor++
            }
        }
    }
</script>