<template>
    <div v-if="$can('registro.menu.producto')">
        <ModuloProducto 
            :utilidadporcentaje="config.utilidadporcentaje"
            :statecodigobarradefault="config.statecodigobarradefault"
            :disabledvencimiento="config.disabledvencimiento"
            :monedasimbolo="config.monedasimbolo"
            :preciocifradecimal="config.preciocifradecimal"
            :preciocifraentera="config.preciocifraentera"
            :precioreglatres="config.precioreglatres"
            :sessionuserid="config.userid"
            :almacenid="config.almacenid"
            :generarlenghtcodigobarra="config.generarlenghtcodigobarra"
        />
    </div>
</template>
    
<script>
    import ModuloProducto from './Modulos/ModuloProducto.vue'
    
    export default {
        
        props: [
            'usuario',
            'config'
        ],

        components: {
            ModuloProducto
        },

        data () {
            return {

            }
        },

        methods: {

        },

        created () {

        }

    }
</script>