<template>
    <div>
        <a-row type="flex" :gutter="[2, 5]">
            <a-divider orientation="left">
				<span style="color:black; font-size:18px;">Catalogo de Productos</span> <span style="color:#9C9C9C; font-size:12px;">Visualiza el listado general</span>
			</a-divider>

			<a-col flex="1 0 100px" v-if="$can('registro.crear.producto')">
				<a-button
                    type="primary" 
                    @click.once="openModalProducto(null, 'agregar')" 
                    :key="buttonKeyRegistrar"
                    block
                    >
                    <a-icon type="plus" /> Nuevo Producto
                </a-button>
			</a-col>
			
			<a-col flex="1 0 100px">
				<a-dropdown>
                    <a-menu slot="overlay">
                        
                        <a-menu-item :key="buttonKeyCorreccionUnidad">
                            <a-popconfirm
                                title="Estas seguro de realizar la correccion de unidades de los productos?"
                                ok-text="Si"
                                cancel-text="No"
                                @confirm="realizarCorrecionUnidad()"
                            >
                                <a href="#"><a-icon type="setting" :style="{ fontSize: '18px', color: '#008B9E' }"/> <strong>Corregir Unidades de Productos</strong></a>
                            </a-popconfirm>
                        </a-menu-item>
                      
					</a-menu>
					<a-button block>Herramientas<a-icon type="down" /> </a-button>
				</a-dropdown>
			</a-col>

			<a-col flex="1 0 100px" v-if="$can('registro.consultar.catalago.por.sucursal')">
                <a-select 
                    label-in-value 
                    :value="{ key: search.almacenID }" 
                    @change="onSearchSucursal"  
                    style="width: 100%"
                    >
                    <a-select-option v-for="(value, index) in lista_almacenes" :key="index" :value="value.almacen_id"> {{ value.nombre }}</a-select-option>
                </a-select>
			</a-col>
			
			<a-col flex="1 0 100px">
                <a-select 
                    :value="search.proveedorID" 
                    @change="onSearchProveedor" 
                    placeholder="Proveedor" 
                    show-search 
                    option-filter-prop="children" 
                    :filter-option="filterOptionProveedor" 
                    style="width: 100%"
                    >
                    <a-select-option v-for="(value, index) in lista_proveedores" :key="index" :value="value.proveedor_id"> {{ value.empresa }}</a-select-option>
                </a-select>
			</a-col>
			
			<a-col flex="1 0 380px">
                <a-input-search placeholder="Buscar en el sistema" @search="onSearch">
                    <a-button type="primary" slot="enterButton">
                        Buscar
                    </a-button>
                </a-input-search>
			</a-col>

            <a-col :span="1">
                <a-button @click="obtenerListadoActual">
                    <a-icon type="reload" />
                </a-button>
            </a-col>
		</a-row>

        <br>

        <a-table 
            :columns="columns" 
            :data-source="listaProductos" 
            :pagination="paginate"
            :loading="loading"
            :ellipsis="true"
            size="small"
            :rowKey="record => record.producto_id"
            :scroll="{ x: 980 }"
            @change="handlePagination"
            >

            <template slot="imagen" slot-scope="item">
                    <template v-if="item.imagenproductos.length === 0">
                        <img class="img-responsive" loading="lazy" :src="imageProductDefault" width="70" :alt="item.producto_id">
                    </template>

                    <template v-if="item.imagenproductos.length > 0">
                        <img 
                            loading="lazy" 
                            class="img-responsive" 
                            :src="item.imagenproductos.filter(x => typeof x!==undefined).shift().location_file" 
                            width="70" 
                            :alt="item.producto_id"
                            onerror="this.style.display='none';"
                            >
                        <a-button type="dashed" size="small" style="margin-top: 5px;" @click="show(item.imagenproductos)">
                            <a-icon type="eye" />Ver
                        </a-button>
                    </template>
            </template>

            <template slot="proveedor" slot-scope="item">
                <span>{{ item.proveedor.empresa }}</span>
            </template>

            <template slot="nombre" slot-scope="item">
                <span>{{ item.nombre }} {{ item.medida }}</span><br>
                <span style="font-size:9px; color:black;">Tienda Online:
                    <span v-if="item.estado_visualizacion === 'HABILITADO'" style="color:#099600;">{{ item.estado_visualizacion }}</span>
                    <span v-if="item.estado_visualizacion === 'DESHABILITADO'" style="color:#960000;">{{ item.estado_visualizacion }}</span>
                </span>
                <template v-if="Object.entries(item.stocks).length === 0">
                    <a-tag color="orange">
                        <span style="font-weight:700; font-size:11px;">Iniciar Inventario Inicial</span>
                    </a-tag>
                </template>
            </template>

            <template slot="codigo" slot-scope="item">
                <span>{{ item.codigo }}</span><br>
                <!--
                <template v-if="Object.entries(item.stocks).length === 0">
                    <a-tag color="red">
                        <span style="font-weight:700; font-size:11px;">No registrado:<br> {{ item.nombreAlmacen }}</span>
                    </a-tag>
                </template>
                -->
            </template>

            <template slot="stock" slot-scope="item">
                <span v-if="item.saldoFisico > 0" style="color:#0d8b00; font-size:17px; font-weight:700;">{{ item.saldoFisico }}</span>
                <span v-if="item.saldoFisico === 0" style="color:#8b0000; font-size:17px; font-weight:700;">{{ item.saldoFisico }}</span>
            </template>

            <template slot="precioventa" slot-scope="item">
                <div style="margin-top: -8px;" class="color-palette-set" v-for="(option, i) in item.precioventa" v-bind:value="option.tipoprecioventaID" :key="i">
                    <div style="background-color:#e0f8dd; padding: 5px;">
                        <a-tooltip placement="topLeft">
                            <template slot="title">
                                <span style="font-size:11px;">{{ option.proveedor.empresa }}</span>
                            </template>
                                <a href="#">
                                    <span style="color:#0d8b00; font-size:17px; font-weight:700;">{{ option.precioValor }}</span>&nbsp;&nbsp;
                                    <span style="font-size:9px; font-weight:700; color:rgb(66, 66, 66);">{{ option.tipoprecioventa }}</span>&nbsp;&nbsp;
                                    <span>
                                        <a-tag v-if="option.estado_presentacion === 'NUEVO'" color="green">
                                            {{ option.estado_presentacion }}
                                        </a-tag>
                                        <a-tag v-if="option.estado_presentacion === 'NORMAL'" color="blue">
                                            {{ option.estado_presentacion }}
                                        </a-tag>
                                        <a-tag v-if="option.estado_presentacion === 'DESCUENTO'" color="red">
                                            {{ option.estado_presentacion }}
                                        </a-tag>
                                        <a-tag v-if="option.estado_presentacion === 'OFERTA'" color="purple">
                                            {{ option.estado_presentacion }}
                                        </a-tag>
                                    </span>
                                    <span>
                                        <a-icon type="check-circle" theme="filled" v-if="option.estado === 'HABILITADO'" :style="{ fontSize: '14px', color: '#07CC04' }"  />
                                    </span>
                                </a>
                        </a-tooltip>
                    </div>
                </div>
            </template>

            <span slot="expandedRowRender" slot-scope="item" style="margin: 0">
                <span style="font-weight:700; font-size:17px;">DATOS DE REGISTRO</span><br>
                <span style="font-size:11px; color:black;">Stock:
                    <a-tag v-if="item.estado_stock === 'HABILITADO'" color="green">
                        {{ item.estado_stock }}
                    </a-tag>
                    <a-tag v-if="item.estado_stock === 'DESHABILITADO'" color="red">
                        {{ item.estado_stock }}
                    </a-tag>
                </span>
                <span style="font-size:11px; color:black;">P/Venta:
                    <a-tag v-if="item.estado_precio_venta === 'HABILITADO'" color="green">
                        {{ item.estado_precio_venta }}
                    </a-tag>
                    <a-tag v-if="item.estado_precio_venta === 'DESHABILITADO'" color="red">
                        {{ item.estado_precio_venta }}
                    </a-tag>
                </span><br>
                <span style="font-size:11px; color:black;">Visualizacion:
                    <a-tag v-if="item.estado_visualizacion === 'HABILITADO'" color="green">
                        {{ item.estado_visualizacion }}
                    </a-tag>
                    <a-tag v-if="item.estado_visualizacion === 'DESHABILITADO'" color="red">
                        {{ item.estado_visualizacion }}
                    </a-tag>
                </span>
                <span style="font-size:11px; color:black;">Descuento:
                    <a-tag v-if="item.descuento > 0" color="red">
                        {{ item.descuento }} %
                    </a-tag>
                    <a-tag v-if="item.descuento === 0" color="blue">
                        {{ item.descuento }} %
                    </a-tag>
                </span>
                <br><br>
                <a-row>
                    <a-col :span="5">
                        <a-list size="small" bordered><a-list-item>ID:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>CODIGO:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>NOMBRE:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>CATEGORIA:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>MEDIDA:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>MARCA:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>SUCURSAL:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>PROVEEDOR:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>FECHA REGISTRO:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>FECHA ACTUALIZACION:</a-list-item></a-list>
                    </a-col>
                    <a-col :span="19">
                        <a-list size="small" bordered><a-list-item>{{ item.producto_id }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.codigo }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.nombre }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.categoria.nombre }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.medida }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.marca.nombre }}</a-list-item></a-list>
                        <a-list size="small" bordered>
                            <a-list-item>
                                <template v-if="Object.entries(item.stocks).length > 0">
                                    <a-tag color="green">
                                        <span style="font-weight:700; font-size:11px;">{{ item.nombreAlmacen }}</span>
                                    </a-tag>
                                </template>
                                <template v-else>
                                    <a-tag color="red">
                                        <span style="font-weight:700; font-size:11px;">NO ASIGNADO A UNA SUCURSAL</span>
                                    </a-tag>
                                </template>
                            </a-list-item>
                        </a-list>
                        <a-list size="small" bordered>
                            <a-list-item>
                                <a-tag color="blue">
                                    <strong>{{ item.proveedor.empresa }}</strong>
                                </a-tag>
                            </a-list-item>
                        </a-list>
                        <a-list size="small" bordered><a-list-item>{{ utils.formatDate(item.created_at) }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ utils.formatDate(item.updated_at) }}</a-list-item></a-list>
                    </a-col>
                </a-row>
            </span>

            <template slot="action" slot-scope="item">
                <a-dropdown>
                    <a-menu slot="overlay">
                        
                        <a-menu-item @click.once="openModalProducto(item, 'editar')" :key="buttonKeyActualizarProducto" v-if="$can('registro.editar.producto')">
                            <a-tooltip placement="topLeft">
                                <template slot="title">
                                    <span style="font-size:11px;">{{ item.codigo }}</span>
                                </template>
                                <a-icon type="form" :style="{ fontSize: '18px', color: '#006ccc' }"/> <strong>Editar Producto</strong>
                            </a-tooltip>
                        </a-menu-item>

                        <a-menu-item @click.once="abrirModalVisualizarProducto(item)" :key="buttonKeyVisualizarProducto" v-if="$can('registro.visualizar.producto')">
                            <a-tooltip placement="topLeft">
                                <template slot="title">
                                    <span style="font-size:11px;">{{ item.codigo }}</span>
                                </template>
                                <a-icon type="eye" :style="{ fontSize: '18px', color: '#0c77ff' }"/> <strong>Visualizar Producto</strong>
                            </a-tooltip>
                        </a-menu-item>

                        <template v-if="Object.entries(item.stocks).length === 0">
                            <a-menu-item @click.once="abrirModalInventarioProducto(item)" :key="buttonKeyCompra" v-if="$can('registro.gestionar.inventario.inicial')">
                                <a-tooltip placement="topLeft">
                                    <template slot="title">
                                        <span style="font-size:11px;">{{ item.codigo }}</span>
                                    </template>
                                    <a-icon type="plus" :style="{ fontSize: '18px', color: '#8eb801' }"/> <strong>Inventario Inicial</strong>
                                </a-tooltip>
                            </a-menu-item>
                        </template>

                        <template>
                            <a-menu-item @click.once="abrirModalAsignarPrecios(item)" :key="buttonKeyNuevaVenta" v-if="$can('registro.gestionar.precio.de.venta')">
                                <a-tooltip placement="topLeft">
                                    <template slot="title">
                                        <span style="font-size:12px;">{{ item.codigo }}</span>
                                    </template>
                                    <a-icon type="dollar" :style="{ fontSize: '18px', color: '#0bb006' }"/> <strong>Precios de Venta</strong>
                                </a-tooltip>
                            </a-menu-item>
                        </template>

                        <template>
                            <a-menu-item @click.once="abrirModalCambiarProveedor(item)" :key="buttonKeyCambiarProveedor" v-if="$can('registro.actualizar.proveedor')">
                                <a-tooltip placement="topLeft">
                                    <template slot="title">
                                        <span style="font-size:12px;">{{ item.codigo }}</span>
                                    </template>
                                    <a-icon type="user-add" :style="{ fontSize: '18px', color: '#363eed' }"/> <strong>Cambiar de Proveedor</strong>
                                </a-tooltip>
                            </a-menu-item>
                        </template>

                        <template>
                            <a-menu-item @click.once="abrirModalActualizarVencimiento(item)" :key="buttonKeyFechaVencimiento" v-if="$can('registro.actualizar.fecha.de.vencimiento')">
                                <a-tooltip placement="topLeft">
                                    <template slot="title">
                                        <span style="font-size:12px;">{{ item.codigo }}</span>
                                    </template>
                                    <a-icon type="calendar" :style="{ fontSize: '18px', color: '#e8ad00' }"/> <strong>Actualizar Fecha de Vencimiento</strong>
                                </a-tooltip>
                            </a-menu-item>
                        </template>

                        <template>
                            <a-menu-item @click.once="abrirModalCategoria(item)" :key="buttonKeyCategoria" v-if="$can('registro.actualizar.categoria')">
                                <a-tooltip placement="topLeft">
                                    <template slot="title">
                                        <span style="font-size:12px;">{{ item.codigo }}</span>
                                    </template>
                                    <a-icon type="edit" :style="{ fontSize: '18px', color: '#06966D' }"/> <strong>Cambiar de Categoria</strong>
                                </a-tooltip>
                            </a-menu-item>
                        </template>

                        <template>
                            <a-menu-item @click.once="abrirModalMarca(item)" :key="buttonKeyMarca" v-if="$can('registro.actualizar.marca')">
                                <a-tooltip placement="topLeft">
                                    <template slot="title">
                                        <span style="font-size:12px;">{{ item.codigo }}</span>
                                    </template>
                                    <a-icon type="edit" :style="{ fontSize: '18px', color: '#068996' }"/> <strong>Cambiar de Marca</strong>
                                </a-tooltip>
                            </a-menu-item>
                        </template>

                        <template>
                            <a-menu-item @click.once="abrirModalUnidad(item)" :key="buttonKeyUnidad" v-if="$can('registro.actualizar.unidad')">
                                <a-tooltip placement="topLeft">
                                    <template slot="title">
                                        <span style="font-size:12px;">{{ item.codigo }}</span>
                                    </template>
                                    <a-icon type="edit" :style="{ fontSize: '18px', color: '#065F96' }"/> <strong>Cambiar de Unidad</strong>
                                </a-tooltip>
                            </a-menu-item>
                        </template>

                        <a-menu-item @click.once="openModalProducto(item, 'eliminar')" :key="buttonKeyEliminarProducto" v-if="$can('registro.eliminar.producto')">
                            <a-tooltip placement="topLeft">
                                <template slot="title">
                                    <span style="font-size:11px;">{{ item.codigo }}</span>
                                </template>
                                <a-icon type="delete" :style="{ fontSize: '18px', color: '#cc2400' }"/> <strong>Eliminar Producto</strong>
                            </a-tooltip>
                        </a-menu-item>

                    </a-menu>
                    <a-button> Acciones <a-icon type="down" /> </a-button>
                </a-dropdown>
            </template>
        </a-table>

        <!-- INICIO PRODUCTOS -->
        <a-modal
            v-model="modalProducto"
            :title="false"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="460"
            :zIndex="1040"
            >

            <div class="row mb-12">
                <ComponenteProducto
                    :tipoproducto="dataprod.tipomodal"
                    :dataproducto="dataprod.producto"
                    :almacenid="parseInt(search.almacenID)"
                    :proveedorid="search.proveedorID"
                    :generarlenghtcodigobarra="generarlenghtcodigobarra"
                    @successProducto="dataSuccessProducto"
                />
            </div>

            <template slot="footer">
                <a-button key="back" @click="modalProducto = false">
                    CERRAR MODAL
                </a-button>
            </template>
        </a-modal>
        <!-- FINAL PRODUCTOS -->

        <!-- CREAR INVENTARIO COMPRA PRODUCTO -->
        <a-modal
            v-model="modalInventarioProducto"
            title="GESTION DE COMPRAS E INVENTARIO INICIAL"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="1280"
            :zIndex="1049"
            >

            <div class="row mb-12">
                <ComponenteCompra
                    :utilidadporcentaje="this.utilidadporcentaje"
                    :preciocifradecimal="this.preciocifradecimal"
                    :preciocifraentera="this.preciocifraentera"
                    :precioreglatres="this.precioreglatres"
                    :monedasimbolo="this.monedasimbolo"
                    :dataproducto="this.compra.dataproducto"
                    :almacenid="this.compra.almacenID"
                    :proveedorid="this.compra.proveedorID"
                    @successCompra="dataSuccessCompra"
                />
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="modalInventarioProducto = false">
                    CERRAR
                </a-button>
            </template>
        </a-modal>
        <!-- CREAR INVENTARIO COMPRA PRODUCTO -->

        <!-- EDITAR PRECIOS DE VENTA -->
        <a-modal
            v-model="openModalEditarPrecioVentaCompra"
            title="ASIGNAR PRECIOS DE VENTA AL PRODUCTO"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="1024"
            :zIndex="1040"
            >

            <div class="mb-12">
                <ModuloPrecioVenta 
                    :monedasimbolo="monedasimbolo"
                    :utilidadporcentaje="utilidadporcentaje"
                    :preciocifradecimal="preciocifradecimal"
                    :preciocifraentera="preciocifraentera"
                    :precioreglatres="precioreglatres"
                    :almacenid="this.asignacion.almacenID"
                    :productoid="this.asignacion.productoID"
                    :proveedorid="this.asignacion.proveedorID"
                    :utilidad="this.asignacion.utilidad"
                    :producto="this.asignacion.producto"
                    :preciocompra="this.asignacion.precioCosto"
                    @successVenta="dataSuccessPrecioVenta"
                />
            </div>
                        
            <template slot="footer">
                <a-button key="back" @click="openModalEditarPrecioVentaCompra = false">
                    Cerrar
                </a-button>
            </template>
        </a-modal>
        <!-- EDITAR PRECIOS DE VENTA -->

        <!-- CAMBIAR PROVEEDOR -->
        <a-modal
            v-model="showModalEditarProveedor"
            title="Actualizar Proveedor"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="480"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <ComponenteCambiarProveedor
                    :producto="this.asignacion.producto"
                    @successCambiarProveedor="dataSuccessCambiarProveedor"
                />
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="showModalEditarProveedor = false">
                    Cancelar
                </a-button>
            </template>
        </a-modal>
        <!-- CAMBIAR PROVEEDOR -->

        <!-- CAMBIAR CATEGORIA -->
        <a-modal
            v-model="showModalCategoria"
            title="Actualizar Categoria"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="480"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <ComponenteActualizarCategoria
                    :producto="this.asignacion.producto"
                    @successActualizarCategoria="dataSuccessCategoria"
                />
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="showModalCategoria = false">
                    Cancelar
                </a-button>
            </template>
        </a-modal>
        <!-- CAMBIAR CATEGORIA -->

        <!-- ACTUALIZAR MARCA -->
        <a-modal
            v-model="showModalEditarMarca"
            title="Actualizar Marca"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="480"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <ComponenteActualizarMarca
                    :producto="this.asignacion.producto"
                    @successActualizarMarca="dataSuccessMarca"
                />
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="showModalEditarMarca = false">
                    Cancelar
                </a-button>
            </template>
        </a-modal>
        <!-- ACTUALIZAR MARCA -->

        <!-- ACTUALIZAR UNIDAD -->
        <a-modal
            v-model="showModalEditarUnidad"
            title="Actualizar Unidad"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="480"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <ComponenteActualizarUnidad
                    :producto="this.asignacion.producto"
                    @successActualizarUnidad="dataSuccessUnidad"
                />
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="showModalEditarUnidad = false">
                    Cancelar
                </a-button>
            </template>
        </a-modal>
        <!-- ACTUALIZAR UNIDAD -->

        <!-- VISUALIZACION PRODUCTO -->
        <a-modal
            v-model="showModalVisualizarProducto"
            title="Detalle de Producto"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="780"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <ComponenteVisualizarProducto
                    :producto="this.asignacion.producto"
                />
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="showModalVisualizarProducto = false">
                    Cancelar
                </a-button>
            </template>
        </a-modal>
        <!-- VISUALIZACION PRODUCTO -->

        <!-- ACTUALIZAR VENCIMIENTO -->
        <a-modal
            v-model="showModalActualizarVencimiento"
            title="Actualizar fecha de Vencimiento"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="560"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <ComponenteActualizarVencimiento
                    :producto="this.asignacion.producto"
                    @successActualizarVencimiento="dataSuccessActualizarVencimiento"
                />
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="showModalActualizarVencimiento = false">
                    Cancelar
                </a-button>
            </template>
        </a-modal>
        <!-- ACTUALIZAR VENCIMIENTO -->

        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1030;">
            <div style="margin-top: 250px;">
                <vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Información..."></vue-simple-spinner>
                <br><br>
                <center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
            </div>
        </div>

        <div v-if="spinnerLoadingIndicator == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1030;">
            <div style="margin-top: 250px;">
                <vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Información..."></vue-simple-spinner>
                <br><br>
                <center><progress-bar :progress="producto.progress_upload"></progress-bar></center>
                <br>
                <center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerLoadingIndicator = false"><i class="fas fa-sync"></i> Cancelar y Volver a Intentar</button></center>
            </div>
        </div>
        
    </div>
</template>

<script>
    const columns = [
        { 
            id: 'producto_id', 
            title: 'Imagen', 
            dataIndex: '',
            key: 'imagen',
            scopedSlots: { customRender: 'imagen' }, 
            width: '15%'
        },
        { 
            id: 'producto_id', 
            title: 'Codigo', 
            dataIndex: '',
            key: 'codigo',
            scopedSlots: { customRender: 'codigo' }, 
            sortDirections: ['descend', 'ascend', false],
            sorter: true,
            width: '25%'
        },
        { 
            id: 'producto_id', 
            title: 'Proveedor', 
            dataIndex: '',
            key: 'proveedor',
            scopedSlots: { customRender: 'proveedor' }, 
            sortDirections: ['descend', 'ascend', false],
            sorter: true,
            width: '20%'
        },
        { 
            id: 'producto_id',
            title: 'Nombre', 
            dataIndex: '',
            key: 'nombre',
            width: '50%',
            scopedSlots: { customRender: 'nombre' }, 
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'producto_id',
            title: 'Stock', 
            dataIndex: '',
            key: 'saldoFisico',
            scopedSlots: { customRender: 'stock' }, 
            width: '16%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'producto_id',
            title: 'P.Venta', 
            dataIndex: '',
            key: 'precioventa',
            scopedSlots: { customRender: 'precioventa' }, 
            width: '53%'
        },
        { 
            id: 'producto_id',
            title: 'Acciones', 
            dataIndex: '',
            scopedSlots: { customRender: 'action' },
            width: '15%'
        }
    ]

	import Vue from 'vue'
	import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
	import moment from 'moment'
	import 'moment/locale/es'
	import * as utils from '@/utils/utils'
	import axios from '@/config/axios/axios'
    import ProgressBar from '../../../plugins/ProgressBar.vue'
    import { mapGetters, mapActions } from 'vuex'

    import { 
		URI_ALMACEN_LISTAR_LAST,
        URI_PROVEEDOR_LISTAR_LAST
	} from '@/utils/constants'

    import ComponenteProducto from '../Components/ComponenteProducto.vue'
    import ComponenteCambiarProveedor from '../Components/ComponenteCambiarProveedor.vue'
    import ComponenteActualizarMarca from '../Components/ComponenteActualizarMarca.vue'
    import ComponenteActualizarUnidad from '../Components/ComponenteActualizarUnidad.vue'
    import ComponenteActualizarCategoria from '../Components/ComponenteActualizarCategoria.vue'
    import ComponenteActualizarVencimiento from '../Components/ComponenteActualizarVencimiento.vue'
    import ComponenteVisualizarProducto from '../Components/ComponenteVisualizarProducto.vue'
    import ComponenteCompra from '../../Compra/Components/ComponenteCompra.vue'
    import ModuloPrecioVenta from '../../Inventario/Modulos/ModuloPrecioVenta.vue'

    export default {

        props: {
            utilidadporcentaje: { type: String, required: true },
            statecodigobarradefault: { type: String, required: true },
            disabledvencimiento: { type: String, required: true },
            monedasimbolo: { type: String, required: true },
            preciocifradecimal: { type: String, required: true },
            preciocifraentera: { type: String, required: true },
            precioreglatres: { type: String, required: true },
            sessionuserid: { type: Number, required: true },
            almacenid: { type: Number, required: true },
            generarlenghtcodigobarra: { type: Number, required: true }
        },

        components: {
            ProgressBar,
            ComponenteProducto,
            ComponenteCompra,
            ModuloPrecioVenta,
            ComponenteCambiarProveedor,
            ComponenteVisualizarProducto,
            ComponenteActualizarVencimiento,
            ComponenteActualizarCategoria,
            ComponenteActualizarMarca,
            ComponenteActualizarUnidad
        },

        data () {
            return {
                columns,
                dateFormat: 'YYYY-MM-DD',
				moment,
				locale,
                utils,
                imageProductDefault: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/producto.jpg' : '/static/assets/images/producto.jpg',
                list: [],
                url: '',
                lista_almacenes: [],
                lista_proveedores: [],
                search: {
                    almacenID: this.almacenid,
                    proveedorID: 'Proveedor',
                    nombreAlmacen: '',
                    keyword: ''
                },
                isActiveCodigo: this.statecodigobarradefault,
                modal: false,
                tipo: '',
                buttonKey: 1,
                buttonKeyGuardarProducto: 50,
                buttonKeyActualizarProducto: 100,
                buttonKeyEliminarProducto: 200,
                buttonKeyVisualizarProducto: 300,
                buttonKeyCambiarProveedor: 400,
                buttonKeyFechaVencimiento: 500,
                buttonKeyRegistrar: 600,
                buttonKeyNuevaVenta: 700,
                buttonKeyCategoria: 800,
                buttonKeyMarca: 900,
                buttonKeyUnidad: 1000,
                buttonKeyActualizarMarca: 1100,
                buttonKeyActualizarUnidad: 1200,
                buttonKeyCorreccionUnidad: 1300,
                showModal: false,
				spinnerloading: false,
                modalProducto: false,
                modalCategoria: false,
                modalUnidad: false,
                modalMarca: false,
                modalProcedencia: false,
                modalProveedor: false,
                modalAlmacen: false,
                loading: false,
                spinnerLoadingIndicator: false,
                form: {
                    codigo_normal: false,
                    codigo_automatico: false,
                    opciones_avanzadas: false,
                    fecha_vencimiento: false,
                    producto_inventariable: false,
                    registrar_stock: false
                },
                /** UPLOAD DE FILE PRODUCTO */
				imageUrl: '',
				fileList: [],
				previewVisible: false,
				previewImage: '',
                dataprod: {
                    producto: {},
                    tipomodal: ''
                },
                showproducto: {
                    codigo: '',
                    nombre: '',
                    medida: ''
                },
                buttonKeyCompra: 70,
                modalInventarioProducto: false,
                compra: {
                    compra_id: '',
                    almacenID: '',
                    proveedorID: '',
                    dataproducto: {}
                },
                openModalEditarPrecioVentaCompra: false,
                asignacion: {
                    tipoprecioventaID: '',
                    almacenID: '',
                    productoID: '',
                    proveedorID: '',
                    utilidad: '',
                    precioventa: '',
                    producto: {},
                    precioCosto: '',
                    preciofacturado: ''
                },
                showModalEditarProveedor: false,
                showModalVisualizarProducto: false,
                showModalActualizarVencimiento: false,
                images: [],
                paginate: {
                    pageSize: 10,
                    current: 1,
                    total: 0,
                    field: 'producto_id',
                    order: 'descend'
                },
                modalConfiguracionProducto: false,
                estado: {
                    presentacion: '',
                    visualizacion: '',
                    stock: '',
                    precioventa: ''
                },
                showModalCategoria: false,
                showModalEditarMarca: false,
                showModalEditarUnidad: false
            }
        }, 

        mounted () {
            this.getAlmacen()
            this.getProveedor()
            this.paginarResultados()
        },

        watch: {
            modal () {
                if (this.modal) {
                    Vue.nextTick().then(() => this.$refs.input.focus())
                }
            }
        },

        computed: {
            ...mapGetters('producto', ['listaProductos'])
        },

        methods: {
            ...mapActions('producto', ['getListaProducto', 'getCorreccionUnidad']),

            getAlmacen () {
                axios.get(URI_ALMACEN_LISTAR_LAST)
                .then(response => {
                    this.lista_almacenes = response.data.lista
                    this.search.almacenID = this.almacenid.toString()
                    this.search.nombreAlmacen = response.data.nombreAlmacen
                })
            },

            getProveedor () {
                axios.get(URI_PROVEEDOR_LISTAR_LAST)
                .then(response => {
                    this.lista_proveedores = response.data.lista
                })
            },

            resetConsultaPaginacion () {
                this.paginate = {
                    pageSize: 10,
                    current: 1,
                    total: 0,
                    field: 'producto_id',
                    order: 'descend'
                }

                this.search.proveedorID = 'Proveedor'
                this.search.keyword = ''
            },

            paginarResultados () {
                this.getListaProducto({ ...this.paginate, ...this.search })
                .then(response => {
                    this.changePaginate(response.data.meta)
                    this.spinnerloading = false
                })
                .catch(error => {
                    this.loading = false
                    utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
                })
            },

            handlePagination (paginate, filters, sorter) {
                this.paginate = {
                    pageSize: paginate.pageSize,
                    current: paginate.current,
                    total: paginate.total,
                    field: (sorter.field) ? sorter.field : 'producto_id',
                    order: (sorter.order) ? sorter.order : 'descend'
                }
                this.paginarResultados()
            },

            changePaginate (data) {
                this.paginate = {
                    pageSize: data.per_page,
                    current: data.current_page,
                    total: data.total,
                    field: 'producto_id',
                    order: 'descend'
                }
            },

            onSearchSucursal (value) {
                this.spinnerloading = true
                this.resetConsultaPaginacion()
                this.search.almacenID = value.key
                this.search.nombreAlmacen = value.label
                this.paginarResultados()
            },

            onSearchProveedor (value) {
                this.spinnerloading = true
                this.resetConsultaPaginacion()
                this.search.proveedorID = value
                this.paginarResultados()
            },

            onSearch (value) {
                this.spinnerloading = true
                this.resetConsultaPaginacion()
                this.search.keyword = value
                this.paginarResultados()
            },

            obtenerListadoActual (value) {
                this.spinnerloading = true
                this.resetConsultaPaginacion()
                this.paginarResultados()
            },

            onSelectPresentacion (value) {
                this.estado.presentacion = value
            },

            onSelectVisualizacion (value) {
                this.estado.visualizacion = value
            },

            onSelectStock (value) {
                this.estado.stock = value
            },

            onSelectPrecioVenta (value) {
                this.estado.precioventa = value
            },

            filterOptionProveedor (input, option) {
                return (
                    option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
                )
            },

            /** MODULO NUEVO Y EDITAR PRODUCTO */

            openModalProducto (data, tipo) {
                this.dataprod.producto = []
                this.dataprod.tipomodal = ''

                setTimeout(function () { 
                    this.dataprod.producto = data
                    this.dataprod.tipomodal = tipo
                    this.modalProducto = true
                    this.buttonKeyRegistrar++
                    this.buttonKeyAgregarProducto++
                    this.buttonKeyActualizarProducto++
                    this.buttonKeyEliminarProducto++
                }.bind(this), 500)
            },

            dataSuccessProducto (newValue) {
                setTimeout(function () {
                    this.paginarResultados()
                    this.modalProducto = false
                }.bind(this), 300)
            },

            /** MODULO DE COMPRAS */

            abrirModalInventarioProducto (item) {
                this.compra.almacenID = ''
                this.compra.proveedorID = ''
                this.compra.dataproducto = []

                setTimeout(function () { 
                    this.compra.almacenID = (item.almacenId === 0) ? this.search.almacenID : item.almacenId.toString()
                    this.compra.proveedorID = item.proveedorID.toString()
                    this.compra.dataproducto = item
                    this.modalInventarioProducto = true
                    this.buttonKeyCompra++
                }.bind(this), 500)
            },

            dataSuccessCompra (newValue) {
                setTimeout(function () { 
                    this.paginarResultados()
                    this.modalInventarioProducto = false
                    this.buttonKeyCompra++
                }.bind(this), 500)
            },

            /** PRECIO DE VENTAS */
            abrirModalAsignarPrecios (item) {
				if (parseInt(this.search.almacenID) !== parseInt(item.almacenId)) {
                    utils.openNotificationWithIcon('info', 'Mensaje : ', 'Este producto debe ingresar como inventario inicial, verifique que este registrado en la sucursal... :).', 'topRight')
				} else {
                    if (Object.entries(item.stocks).length > 0) {
                        // Para crear precios de venta es necesario un stock actual igual o mayor a cero
                        this.asignacion = []

                        setTimeout(function () { 
                            this.asignacion.producto = item
                            this.asignacion.almacenID = parseInt(item.almacenId)
                            this.asignacion.productoID = item.producto_id
                            this.asignacion.proveedorID = item.proveedorID
                            this.asignacion.utilidad = item.porcentajeUtilidad
                            this.asignacion.precioCosto = Object.values(item.stocks).filter(x => typeof x !== 'undefined').shift().costo
                            this.openModalEditarPrecioVentaCompra = true
                        }.bind(this), 500)
                        this.buttonKeyNuevaVenta++
                    } else {
                        utils.openNotificationWithIcon('warning', 'Mensaje : ', 'Para crear precios de venta es necesario tener un stock igual o mayor a cero', 'topRight')
                    }
                }

                this.buttonKeyNuevaVenta++
			},

            dataSuccessPrecioVenta (newValue) {
                setTimeout(function () { 
                    this.paginarResultados()
                    this.buttonKeyNuevaVenta++
                }.bind(this), 300)
            },

            /** MODULO DE CAMBIAR PROVEEDOR */
            abrirModalCambiarProveedor (item) {
                this.asignacion.producto = []

                setTimeout(function () { 
                    this.asignacion.producto = item
                    this.showModalEditarProveedor = true
                    this.buttonKeyCambiarProveedor++
                }.bind(this), 500)
            },

            dataSuccessCambiarProveedor (newValue) {
                setTimeout(function () { 
                    this.paginarResultados()
                    this.showModalEditarProveedor = false
                    this.buttonKeyCambiarProveedor++
                }.bind(this), 300)
            },

            /** MODULO DE CATEGORIA */
            abrirModalCategoria (item) {
                this.asignacion.producto = []

                setTimeout(function () { 
                    this.asignacion.producto = item
                    this.showModalCategoria = true
                    this.buttonKeyCategoria++
                }.bind(this), 500)
            },

            dataSuccessCategoria (newValue) {
                setTimeout(function () { 
                    this.paginarResultados()
                    this.showModalCategoria = false
                    this.buttonKeyCategoria++
                }.bind(this), 300)
            },

            /** MODULO DE ACTUALIZAR MARCA */
            abrirModalMarca (item) {
                this.asignacion.producto = []

                setTimeout(function () { 
                    this.asignacion.producto = item
                    this.showModalEditarMarca = true
                    this.buttonKeyActualizarMarca++
                }.bind(this), 500)
            },

            dataSuccessMarca (newValue) {
                setTimeout(function () { 
                    this.paginarResultados()
                    this.showModalEditarMarca = false
                    this.buttonKeyActualizarMarca++
                }.bind(this), 300)
            },

            /** MODULO DE ACTUALIZAR UNIDAD */
            abrirModalUnidad (item) {
                this.asignacion.producto = []

                setTimeout(function () { 
                    this.asignacion.producto = item
                    this.showModalEditarUnidad = true
                    this.buttonKeyActualizarUnidad++
                }.bind(this), 500)
            },

            dataSuccessUnidad (newValue) {
                setTimeout(function () { 
                    this.paginarResultados()
                    this.showModalEditarUnidad = false
                    this.buttonKeyActualizarUnidad++
                }.bind(this), 300)
            },

            /** MODULO DE VISUALIZAR PRODUCTO */
            abrirModalVisualizarProducto (item) {
                this.asignacion.producto = []

                setTimeout(function () { 
                    this.asignacion.producto = item
                    this.showModalVisualizarProducto = true
                    this.buttonKeyVisualizarProducto++
                }.bind(this), 500)
            },

            /** MODULO DE ACTUALIZAR FECHA VENCIMIENTO */
            abrirModalActualizarVencimiento (item) {
                this.asignacion.producto = []

                setTimeout(function () { 
                    this.asignacion.producto = item
                    this.showModalActualizarVencimiento = true
                    this.buttonKeyFechaVencimiento++
                }.bind(this), 500)
            },

            dataSuccessActualizarVencimiento (newValue) {
                setTimeout(function () { 
                    this.paginarResultados()
                    this.showModalActualizarVencimiento = false
                    this.buttonKeyFechaVencimiento++
                }.bind(this), 300)
            },

            show (images) {
                const result = images.map((value, key) => {
                    return value.location_file
                })
                
                this.$viewerApi({
                    images: result
                })
            },

            realizarCorrecionUnidad () {
                this.spinnerloading = true
				this.getCorreccionUnidad()
                .then(response => {
                    if (response.status === 200) {
                        this.spinnerloading = false
                        utils.openNotificationWithIcon('success', 'Mensaje : ', 'Se ha registrado correctamente la accion...', 'topRight')
                    }
				})
                .catch(error => {
					this.loading = false
					utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
				})
                this.buttonKey++
            }   
        }
    }
</script>

<style>

    .modal-backdrop.show {
        opacity: 0.92 !important;
    }
    
    .modal-backdrop, .side-modal , .mostrar {
        display: block !important; 
    }

    .modal-dialog {
        position: relative;
        width: auto;
        max-width: 850px;
        margin: 10px;
    }

    .modal-dialog-x {
        position: relative;
        width: auto;
        max-width: 1500px;
        margin: 10px;
    }

    .modal-sm {
        max-width: 300px;
    }
    
    .modal-lg {
        max-width: 850px;
    }

    .modal-lg-x {
        max-width: 1500px;
    }

    .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
    }

    @media (min-width: 768px) {
        .modal-dialog {
            margin: 30px auto;
        }
    }
    @media (min-width: 320px) {
        .modal-sm {
            margin-right: auto;
            margin-left: auto;
        }
    }
    @media (min-width: 620px) {
        .modal-dialog {
            margin-right: auto;
            margin-left: auto;
        }
        .modal-lg {
            margin-right: 10px;
            margin-left: 10px;
        }
    }
    @media (min-width: 920px) {
        .modal-lg {
            margin-right: auto;
            margin-left: auto;
        }
    }

    .btn-opciones-green {
        background-color:#54d406;
        color:#fff;
    }

    .btn-opciones-blue {
        background-color:#024bac;
        color:#fff;
    }

    .tabla-default-primary{
        background-color:#e1e1e1;
        border-top: 3px solid rgb(32, 80, 129);
    }

    /*== BOTON DE PRECIO DE VENTA ===*/
    .boton-asignar{ top: 24px; }

    @media (max-width: 414px) {
        .boton-asignar{ top: 0px; }
    }

    @media (max-width: 375px) {
        .boton-asignar{ top: 0px; }
    }

    @media (max-width: 360px) {
        .boton-asignar{ top: 0px; }
    }

    @media (max-width: 320px) {
        .boton-asignar{ top: 0px; }
    }

    .example {
    margin: 20px;
    }
    .example input {
    display: none;
    }
    .example label {
    margin-right: 20px;
    display: inline-block;
    cursor: pointer;
    }

    .ex1 span {
    display: block;
    padding: 5px 10px 5px 25px;
    border: 2px solid #ddd;
    border-radius: 5px;
    position: relative;
    transition: all 0.25s linear;
    }
    .ex1 span:before {
    content: '';
    position: absolute;
    left: 5px;
    top: 50%;
    -webkit-transform: translatey(-50%);
            transform: translatey(-50%);
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #ddd;
    transition: all 0.25s linear;
    }
    .ex1 input:checked + span {
    background-color: #fff;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    }
    .ex1 .red input:checked + span {
    color: red;
    border-color: red;
    }
    .ex1 .red input:checked + span:before {
    background-color: red;
    }
    .ex1 .green input:checked + span {
    color: rgb(31, 211, 14);
    border-color: rgb(31, 211, 14);
    }
    .ex1 .green input:checked + span:before {
    background-color: rgb(31, 211, 14);
    }
    .ex1 .blue input:checked + span {
    color: rgb(45, 186, 204);
    border-color: rgb(45, 186, 204);
    }
    .ex1 .blue input:checked + span:before {
    background-color: rgb(45, 186, 204);
    }
    .ex1 .orange input:checked + span {
    color: orange;
    border-color: orange;
    }
    .ex1 .orange input:checked + span:before {
    background-color: orange;
    }
    .ex1 .lila input:checked + span {
    color: rgb(172, 45, 204);
    border-color: rgb(172, 45, 204);
    }
    .ex1 .lila input:checked + span:before {
    background-color: rgb(172, 45, 204);
    }

</style>