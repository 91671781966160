<template>
    <div>
        <div class="col-md-12">
            <table width="100%" border="1">
                <tr>
                    <td style="font-size:13px; font-weight:700;">CODIGO: </td>
                    <td style="font-size:13px; font-weight:700; color:black;">{{ producto.codigo }}</td>
                </tr>
                <tr>
                    <td style="font-size:13px; font-weight:700;">NOMBRE: </td>
                    <td colspan="3" style="font-size:13px; font-weight:700; color:black;">{{ producto.nombre }}</td>
                </tr>
                <tr>
                    <td style="font-size:13px; font-weight:700;">PROVEEDOR ACTUAL: </td>
                    <td colspan="3" style="font-size:13px; font-weight:700; color:black;">{{ producto.proveedor.empresa }}</td>
                </tr>
            </table>
            <br>
            <label for="proveedor" class="control-label">ELEGIR NUEVO PROVEEDOR</label>
            <a-select size="large" :value="proveedor.proveedorID" @change="onSearchProveedor" show-search option-filter-prop="children" :filter-option="filterOptionProveedor" style="width: 100%">
                <a-select-option v-for="(value, index) in lista_proveedores" :key="index" :value="value.proveedor_id"> {{ value.empresa }}</a-select-option>
            </a-select>

            <br><br>

            <a-button 
                type="primary"
                block
                size="large"
                @click.once="editarProductoProveedor()" 
                :key="buttonKeyProductoProveedor"
                >
                <a-icon type="form" :style="{ fontSize: '22px', color: '#FFFFFF', fontWeight: '700' }"/> CAMBIAR PROVEEDOR 
            </a-button>
        </div>
    
        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 99999;">
            <div style="margin-top: 250px;">
                <vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
                <br><br>
                <center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
            </div>
        </div>
    </div>
</template>
    
<script>
    import axios from '@/config/axios/axios'
    import * as utils from '@/utils/utils'
    import Swal from 'sweetalert2'

    import { 
		URI_PROVEEDOR_LISTAR,
        URI_PRODUCTO_CAMBIAR_PROVEEDOR
	} from '@/utils/constants'

    export default {
    
        props: {
            producto: { required: true }
        },
    
        data () {
            return {
                lista_proveedores: [],
                spinnerloading: false,
                proveedor: {
                    productoID: '',
                    proveedorID: '',
                    nombre: ''
                },
                buttonKeyProductoProveedor: false
            }
        },
    
        created () {
            this.getProveedores()
            this.initData(this.producto)
        },
    
        methods: {

            initData (item) {
                this.proveedor.proveedorID = ''
                this.proveedor.nombre = ''
                
                setTimeout(function () { 
                    this.proveedor.proveedorID = item.proveedorID.toString()
                    this.proveedor.nombre = this.producto.proveedor
                }.bind(this), 300)
            },

            filterOptionProveedor (input, option) {
                return (
                    option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
                )
            },

            getProveedores () {
                axios.get(URI_PROVEEDOR_LISTAR)
                .then(response => {
                    this.lista_proveedores = response.data
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },

            onSearchProveedor (value) {
                this.proveedor.proveedorID = value
            },
    
            editarProductoProveedor () {
                Swal.fire({
                        title: 'Desea Actualizar y Guardar?',
                        text: 'Tome en cuenta que debera elegir el proveedor para el cambio correspondiente en el sistema.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, Proceder Ahora',
                        cancelButtonClass: 'btn btn-danger',
                        confirmButtonClass: 'btn btn-success',
                        cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
                        this.spinnerloading = true
                        const formData = new FormData()
                        formData.append('productoID', this.producto.producto_id)
                        formData.append('proveedorID', (this.proveedor.proveedorID === '') ? '' : this.proveedor.proveedorID)
    
                        axios.post(URI_PRODUCTO_CAMBIAR_PROVEEDOR, formData)
                        .then(response => {
                            if (response.status === 201) {
                                this.$emit('successCambiarProveedor', response.data.message)
                                this.spinnerloading = false
                                utils.openNotificationWithIcon('success', 'Mensaje : ', 'Haz realizado con éxito el proceso de la información', 'topRight')
                            }
                        })
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                    }
                })
                this.buttonKeyProductoProveedor++
            }
        },
    
        computed: {
    
        }
    }
</script>