<template>
    <div>     
        <div class="col-md-12">
            <table width="100%" border="1">
                <tr>
                    <td style="font-size:13px; font-weight:700;">CODIGO: </td>
                    <td style="font-size:13px; font-weight:700; color:#000000;">{{ producto.codigo }}</td>
                </tr>
                <tr>
                    <td style="font-size:13px; font-weight:700;">NOMBRE: </td>
                    <td colspan="3" style="font-size:13px; font-weight:700; color:#000000;">{{ producto.nombre }}</td>
                </tr>
                <tr>
                    <td style="font-size:13px; font-weight:700;">DESCRIPCION: </td>
                    <td colspan="3" style="font-size:13px; font-weight:700; color:#000000;" v-html="utils.decodeHTMLEntities(producto.descripcion)"></td>
                </tr>
                <tr>
                    <td style="font-size:13px; font-weight:700;">CATEGORIA: </td>
                    <td colspan="3" style="font-size:13px; font-weight:700; color:#000000;">{{ producto.categoria.nombre }}</td>
                </tr>
                <tr>
                    <td style="font-size:13px; font-weight:700;">MARCA: </td>
                    <td colspan="3" style="font-size:13px; font-weight:700; color:#000000;">{{ producto.marca.nombre }}</td>
                </tr>
                <tr>
                    <td style="font-size:13px; font-weight:700;">MEDIDA: </td>
                    <td colspan="3" style="font-size:13px; font-weight:700; color:#000000;">{{ producto.medida }}</td>
                </tr>
                <tr>
                    <td style="font-size:13px; font-weight:700;">UNIDAD: </td>
                    <td colspan="3" style="font-size:13px; font-weight:700; color:#000000;">{{ producto.unidad.nombre }}</td>
                </tr>
                <tr>
                    <td style="font-size:13px; font-weight:700;">SUCURSAL: </td>
                    <td colspan="3" style="font-size:13px; font-weight:700; color:#000000;">{{ producto.nombreAlmacen }}</td>
                </tr>
                <tr>
                    <td style="font-size:13px; font-weight:700;">PROVEEDOR: </td>
                    <td colspan="3" style="font-size:13px; font-weight:700; color:#000000;">{{ producto.proveedor.empresa }}</td>
                </tr>
            </table>
        </div>
    </div>
</template>
    
<script>
    import * as utils from '@/utils/utils'

    export default {
        
        props: {
            producto: { required: true }
        },

        data () {
            return {
                utils
            }
        }
    }
</script>