<template>
    <div>
        <div class="col-md-12">
            <table width="100%" border="1">
                <tr>
                    <td style="font-size:13px; font-weight:700;">CODIGO: </td>
                    <td style="font-size:13px; font-weight:700; color:black;">{{ producto.codigo }}</td>
                </tr>
                <tr>
                    <td style="font-size:13px; font-weight:700;">NOMBRE: </td>
                    <td colspan="3" style="font-size:13px; font-weight:700; color:black;">{{ producto.nombre }}</td>
                </tr>
                <tr>
                    <td style="font-size:13px; font-weight:700;">MARCA ACTUAL: </td>
                    <td colspan="3" style="font-size:13px; font-weight:700; color:black;">{{ producto.marca.nombre }}</td>
                </tr>
            </table>
            <br>
            <a-col :span="20">
                <label for="almacen" class="control-label">Marca: <span style="font-size:11px; font-weight:700; color:#05729b;"> Opcional *</span></label>
                <a-select 
                    :value="data.marcaID" 
                    @change="onSelectMarca" 
                    show-search 
                    option-filter-prop="children" 
                    :filter-option="filterOptionMarca" 
                    style="width:100%"
                    size="large"
                    >
                    <a-select-option v-for="(value, index) in marcas" :key="index" :value="value.marca_id"> {{ value.nombre }}</a-select-option>
                </a-select>
            </a-col>
            <a-col :span="3">
                <a-button 
                    size="large" 
                    type="dashed"
                    style="top: 26px;"
                    @click="abriModalSecundary('marca')"
                    >
                    <a-icon type="plus" :style="{ fontSize: '18px', color: '#08c' }" />
                </a-button>
            </a-col>
            <br><br><br><br>
            <a-button 
                type="primary"
                size="large"
                block 
                @click.once="editarMarca()" 
                :key="buttonKeyEditarMarca"
                >
                <a-icon type="form" :style="{ fontSize: '22px', color: '#FFFFFF', fontWeight: '700' }"/> ACTUALIZAR MARCA
            </a-button>
        </div>

        <!--MARCAS-->
        <a-modal
            v-model="modalMarca"
            title="GESTION DE MARCA"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :width="420"
            :zIndex="1051"
            >
    
            <div class="row mb-12">
                <div class="form-group">
                    <label>Nombre: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                    <a-input size="large" v-model="data.nombreMarca" placeholder="Nombre de la Marca" onfocus="myFunction(this)"/>
                </div>
            </div>
    
            <template slot="footer">
                <a-button key="back" @click="modalMarca = false">
                    CANCELAR
                </a-button>
                <a-button @click.once="guardar()" :key="buttonKey" type="primary" :loading="loading">GUARDAR MARCA</a-button>
            </template>
        </a-modal>
    
        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 99999;">
            <div style="margin-top: 250px;">
                <vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
                <br><br>
                <center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
            </div>
        </div>
    </div>
</template>
    
<script>
    import axios from '@/config/axios/axios'
    import * as utils from '@/utils/utils'
    import Swal from 'sweetalert2'
    import { mapGetters, mapActions } from 'vuex'

    import { 
        URI_PRODUCTO_CAMBIAR_PROVEEDOR
	} from '@/utils/constants'

    export default {
    
        props: {
            producto: { required: true }
        },
    
        data () {
            return {
                spinnerloading: false,
                buttonKey: 10,
                loading: false,
                modalMarca: false,
                data: {
                    productoID: '',
                    codigo: '',
                    nombre: '',
                    marcaID: '',
                    nombreMarca: ''
                },
                buttonKeyEditarMarca: false
            }
        },
    
        created () {
            this.initData(this.producto)
            this.getMarca()
        },
    
        computed: {
            ...mapGetters('marca', ['marcas'])
        },

        methods: {
            ...mapActions('marca', ['getMarca', 'guardarMarca']),
            ...mapActions('producto', ['updateMarca']),

            initData (item) {
                this.data.productoID = ''
                this.data.marcaID = ''
                this.data.codigo = ''
                this.data.nombre = ''
                this.data.nombreMarca = ''
                setTimeout(function () { 
                    this.data.productoID = item.producto_id.toString()
                    this.data.marcaID = item.marcaID.toString()
                    this.data.codigo = item.codigo
                    this.data.nombre = item.nombre
                    this.data.nombreMarca = item.marca
                }.bind(this), 300)
            },

            filterOptionMarca (input, option) {
                return (
                    option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
                )
            },

            onSelectMarca (value) {
                this.data.marcaID = value
            },

            abriModalSecundary (tipo) {
                switch (tipo) {
                    case 'marca':
                        this.modalMarca = true
                        break
                    default:
                }
            },
    
            guardar () {
                this.spinnerloading = true
				this.guardarMarca(this.data)
                .then(response => {
                    if (response.status === 201) {
                        this.getMarca()
                        this.modalMarca = false
                        this.spinnerloading = false
                    }
				})
                .catch(error => {
					this.loading = false
					utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
				})
                this.buttonKey++
            },

            editarMarca () {
                this.spinnerloading = true
				this.updateMarca(this.data)
                .then(response => {
                    if (response.status === 201) {
                        this.$emit('successActualizarMarca', response.data.message)
					    utils.openNotificationWithIcon('success', 'Exito', response.data, 'topRight')
                        this.modalMarca = false
                        this.spinnerloading = false
                    }
				})
                .catch(error => {
					this.loading = false
					utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
				})
                this.buttonKeyEditarMarca++
            }
        }
    }
</script>