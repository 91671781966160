<template>
    <div>
        
        <div class="col-md-12">
            <table width="100%" border="1">
                <tr>
                    <td style="font-size:13px; font-weight:700;">Codigo: </td>
                    <td style="font-size:13px; font-weight:700; color:black;">{{ producto.codigo }}</td>
                </tr>
                <tr>
                    <td style="font-size:13px; font-weight:700;">Nombre: </td>
                    <td colspan="3" style="font-size:13px; font-weight:700; color:black;">{{ producto.nombre }}</td>
                </tr>
            </table>

            <br>
            
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>F. Elaboracion :</label>
                        <a-date-picker size="large" :value="getDateFormat(fecha.elaboracion)" @change="onChangeElaboracion" :locale="locale" style="width: 100%"/>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>F. Vencimiento :</label>
                        <a-date-picker size="large" :value="getDateFormat(fecha.vencimiento)" @change="onChangeVencimiento" :locale="locale" style="width: 100%"/>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>Accion:</label><br>
                        <a-button 
                            block
                            size="large"
                            @click="aplicarFechaDefecto()" 
                            type="dashed" 
                            >
                            <a-icon type="check" /> Aplicar Fecha
                        </a-button>
                    </div>
                </div>
            </div>

            <a-button
                type="primary"
                size="large" 
                block
                @click.once="agregarVencimientoProducto()" 
                :key="buttonKeyActualizarVencimiento"
                >
                <a-icon type="form" :style="{ fontSize: '22px', color: '#FFFFFF', fontWeight: '700' }"/> Actualizar Vencimiento
            </a-button>
        </div>
    
        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 99999;">
            <div style="margin-top: 250px;">
                <vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
                <br><br>
                <center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
            </div>
        </div>

    </div>
</template>
    
<script>
	import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
	import moment from 'moment'
	import 'moment/locale/es'
	import * as utils from '@/utils/utils'
	import axios from '@/config/axios/axios'
    import VueSimpleSpinner from 'vue-simple-spinner'

    import { 
		URI_PRODUCTO_EDITAR_FECHA
	} from '@/utils/constants'

    export default {
    
        props: {
            producto: { required: true }
        },
    
        data () {
            return {
                dateFormat: 'YYYY-MM-DD',
				moment,
				locale,
                spinnerloading: false,
                fecha: {
                    elaboracion: '',
                    vencimiento: ''
                },
                nombreProveedor: '',
                buttonKeyActualizarVencimiento: false
            }
        },
    
        created () {
            this.initData(this.producto)
        },
    
        methods: {

            getDateFormat (date) {
				var d = moment(date)
				return date && d.isValid() ? d : ''
			},

            onChangeElaboracion (date, dateString) {
				this.fecha.elaboracion = dateString
			},

            onChangeVencimiento (date, dateString) {
				this.fecha.vencimiento = dateString
			},

            initData (item) {
                const nombreProveedor = (typeof this.producto.proveedor === 'string') ? this.producto.proveedor : this.producto.proveedor.empresa
                this.nombreProveedor = nombreProveedor
                this.fecha.elaboracion = ''
                this.fecha.vencimiento = ''

                setTimeout(function () {
                    this.fecha.elaboracion = (item.elaboracion === null || item.elaboracion === '') ? moment().format('YYYY-MM-DD') : item.elaboracion
				    this.fecha.vencimiento = (item.vencimiento === null || item.vencimiento === '') ? moment().add('years', 1).format('YYYY-MM-DD') : item.vencimiento
                }.bind(this), 300)
            },

            aplicarFechaDefecto () {
				this.fecha.elaboracion = moment().format('YYYY-MM-DD')
				this.fecha.vencimiento = moment().add('years', 1).format('YYYY-MM-DD')
			},

            agregarVencimientoProducto () {
				this.spinnerloading = true
                const formData = new FormData()
                formData.append('productoID', this.producto.producto_id)
                formData.append('elaboracion', this.fecha.elaboracion)
                formData.append('vencimiento', this.fecha.vencimiento)

                axios.post(URI_PRODUCTO_EDITAR_FECHA, formData)
                .then(response => {
                    if (response.status === 201) {
                        this.$emit('successActualizarVencimiento', response.data.message)
                        this.spinnerloading = false
                        utils.openNotificationWithIcon('success', 'Mensaje : ', 'Haz realizado con éxito el proceso de la información', 'topRight')
                    }
                })
                .catch(error => {
					utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
                this.buttonKeyActualizarVencimiento++
            }
        },
    
        computed: {
    
        }
    }
</script>